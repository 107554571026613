import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import TurbolinksAdapter from "vue-turbolinks"
import Vue from "vue/dist/vue.esm"
import axios from "axios"
import VueAxios from "vue-axios"
import VueRouter from "vue-router"
import Vuex from "vuex"
import VueTheMask from "vue-the-mask"
import initialize from "../initializers"

import "channels"
import "bootstrap"


import { i18n } from "../plugins/i18n"
import "../plugins/vee-validate"
import "../plugins/bootstrap-vue"
import ApiPlugin from "../plugins/api"

initialize(Vue)

// Stylesheets
import "../stylesheets/application.scss"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

const axiosAdm = axios.create({
  baseURL: "/api/v1/admin",
})

Vue.use(VueAxios, { axios, axiosAdm })
Vue.use(VueRouter)
Vue.use(TurbolinksAdapter)
Vue.use(Vuex)
Vue.use(ApiPlugin)
Vue.use(VueTheMask)

Vue.prototype.$addedProps = {}

const usersRoutes = {
  UserConfirmation:          () => import("../views/users/Confirmation.vue"),
  UserEditRegistration:      () => import("../views/users/EditRegistration"),
  UserPassword:              () => import("../views/users/Password.vue"),
  UserRegistration:          () => import("../views/users/Registration"),
  AdminUsersList:            () => import("../views/users/List.vue"),
  AdminUsersShow:            () => import("../views/users/Show.vue"),
  AdminUsersEmailChangesNew: () => import("../views/users/email_changes/New.vue"),
}

const sectionsRoutes = {
  AdminSectionsList: () => import("../views/sections/List.vue"),
}

const subscriptionsRoutes = {
  AdminSubscriptionsCoursesList: () => import("../views/subscriptions/CoursesList.vue"),
  AdminSubscriptionsShow:        () => import("../views/subscriptions/Show"),
}

const categoriesRoutes = {
  AdminCategoriesList: () => import("../views/categories/List.vue"),
}

const certificatesRoutes = {
  AdminCertificatesList: () => import("../views/certificates/List.vue"),
}

const contentsRoutes = {
  AdminContentsList: () => import("../views/sections/contents/List.vue"),
  AdminContentsShow: () => import("../views/sections/contents/Show"),
  AdminContentsEdit: () => import("../views/sections/contents/Edit"),
}

const rolesRoutes = {
  AdminRolesList: () => import("../views/roles/List.vue"),
  AdminRolesEdit: () => import("../views/roles/Edit.vue"),
}

const coursesRoutes = {
  AdminCoursesList:          () => import("../views/courses/List.vue"),
  AdminCoursesEdit:          () => import("../views/courses/Edit"),
  AdminCoursesShow:          () => import("../views/courses/Show.vue"),
  //
  AdminCoursesVisibility:    () => import("../views/courses/visibility/Visibility.vue"),
  AdminCoursesEditorsList:   () => import("../views/courses/editors/List.vue"),
  AdminCourseProgramContent: () => import("../views/courses/programContent"),
}

const selectionProcessesRoutes = {
  AdminSelectionProcessesList:           () => import("../views/selection_processes/List.vue"),
  AdminSelectionProcessesEdit:           () => import("../views/selection_processes/Edit.vue"),
  AdminSelectionProcessesDashboard:      () => import("../views/selection_processes/dashboard/Dashboard.vue"),
  AdminSelectionProcessesDistribution:   () => import("../views/selection_processes/distribution/Distribution.vue"),
  AdminSelectionProcessesPhaseList:      () => import("../views/selection_processes/phase/List.vue"),
  AdminSelectionProcessesEnrollmentList: () => import("../views/selection_processes/enrollment/List.vue"),
}

const reportsRoutes = {
  AdminReportsList:                              () => import("../views/reports/List"),
  AdminReportsPersonalDataAccessList:            () => import("../views/reports/PersonalDataAccessList"),
  AdminReportsSelectionProcessesList:            () => import("../views/reports/SelectionProcessesList"),
  AdminReportsSelectionProcessSubscriptionsList: () => import("../views/reports/SelectionProcessSubscriptionsList"),
  AdminReportsUsersList:                         () => import("../views/reports/users/List"),
}

document.addEventListener("turbolinks:load", () => {
  const csrfToken = document.querySelector("meta[name=\"csrf-token\"]").getAttribute("content")

  axios.defaults.headers.common["X-CSRF-Token"] = csrfToken
  axiosAdm.defaults.headers.common["X-CSRF-Token"] = csrfToken

  new Vue({
    el:         ".lms-app",
    i18n,
    components: {
      AppCardHeading: () => import("components/AppCardHeading.vue"),
      FlashAlert:     () => import("components/shared/Alert"),
      AdminFormsList: () => import("../views/forms/List.vue"),
      AdminDashboard: () => import("../views/dashboard/Dashboard.vue"),

      ...usersRoutes,
      ...sectionsRoutes,
      ...subscriptionsRoutes,
      ...categoriesRoutes,
      ...certificatesRoutes,
      ...contentsRoutes,
      ...rolesRoutes,
      ...coursesRoutes,
      ...selectionProcessesRoutes,
      ...reportsRoutes
    },
  })
})

// Stimulus
import "controllers"
